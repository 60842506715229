@tailwind base;
@tailwind components;
@tailwind utilities;
@unocss all;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    /* --primary: 222.2 47.4% 11.2%; */
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    /* --input: 214.3 31.8% 91.4%; */
    /* --ring: 222.2 84% 4.9%; */
    --ring: 44 82% 65%;
    --input: 44 82% 65%;
    --primary: 358 81% 44%;
    /* --radius: 0.5rem; */
    --radius: 10px;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  /* font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;  */
  /* font-family: "SF Pro SC", "SF Pro Display", "SF Pro Icons", "PingFang SC",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif; */
  /* font-family: "SF Pro JP", "SF Pro Display", "SF Pro Icons", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "Helvetica Neue", Helvetica, Arial, sans-serif; */
}

body.ja {
  font-family:
    "Yu Gothic Medium",
    "游ゴシック Medium",
    "游ゴシック体",
    YuGothic,
    "游ゴシック",
    "Yu Gothic",
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    Meiryo,
    sans-serif;
  /* font-weight:600 */
}
body.en {
  font-family:
    SF Pro Text,
    SF Pro Icons,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  font-weight: 400;
  letter-spacing: -0.022em;
}
body.zh {
  font-family: "SF Pro SC", "HanHei SC", "SF Pro Text", "Myriad Set Pro",
    "SF Pro Icons", "Apple Legacy Chevron", "PingFang SC", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.jp {
  font-family: var(--font-jp) !important;
}
.home {
  font-family: var(--font-jp2) !important;
}
.ka {
  font-family: var(--font-ka) !important;
}
.heart.clicked {
  animation: heartbeat 0.6s ease-in-out;
}
.drop {
  filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.2));
}

.path {
  stroke-dasharray: 300; /* 设置为路径长度 */
  stroke-dashoffset: 300; /* 初始时隐藏路径 */
  animation: draw 2s forwards; /* 动画持续时间和方向 */
}

@keyframes draw {
  to {
    stroke-dashoffset: 0; /* 最终状态 */
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient {
  background: linear-gradient(
    154deg,
    rgba(176, 117, 21, 1),
    rgba(244, 221, 137, 1),
    rgba(255, 255, 215, 1),
    rgba(240, 218, 137, 1),
    rgba(176, 117, 21, 1),
    rgba(244, 221, 137, 1),
    rgba(255, 255, 215, 1),
    rgba(240, 218, 137, 1),
    rgba(176, 117, 21, 1)
  );
  background-size: 300% 300%; /* 增加背景的大小以便于动画 */
  animation: gradientAnimation 10s ease infinite; /* 设置动画属性 */
}
.animated-gradient.stop {
  background: linear-gradient(
    154deg,
    rgba(176, 117, 21, 1),
    rgba(244, 221, 137, 1),
    rgba(255, 255, 215, 1),
    rgba(240, 218, 137, 1),
    rgba(176, 117, 21, 1),
    rgba(244, 221, 137, 1),
    rgba(255, 255, 215, 1),
    rgba(240, 218, 137, 1),
    rgba(176, 117, 21, 1)
  );
  background-size: 300% 300%; /* 增加背景的大小以便于动画 */
  animation: none 10s ease infinite; /* 设置动画属性 */
}

img {
  max-width: 100%;
}

img.error {
  display: inline-block;
  transform: scale(1);
  content: "";
  color: transparent;
}
img.error::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f5f5f5 url(/imgs/Vector.svg) no-repeat center / 20% 20%;
}
input::placeholder {
  font-weight: 400;
  color: #999;
  font-size: 13px;
}

a {
  font-size: 13px;
  /* color:#cd151c; */
  /* text-decoration-line: underline; */
  text-underline-offset: 2px;
}

.tap-area {
  position: relative;
}
.tap-area::after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: 2;
}
